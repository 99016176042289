import Cookies from "universal-cookie";
import {
    AxiosResponseTenantRegistration,
    FreightForwarderApproved,
    FreightForwarderApprovedAxiosResponseType,
    GetTokenAxiosResponseType,
    RefreshAxiosResponse,
    RegisterFirstStepAxiosResponse,
    ResetPasswordAxiosResponse,
} from "../redux/types/AuthTypes/auth.types";
import { instence } from "./authAPI";
import { BrokerApproveItem } from "../redux/types/PortalTypes/portal.types";

export const registerAPI = {
    getDocusignContinueData(cookies: Cookies) {
        return instence.get<unknown, { data: BrokerApproveItem }>(`/docusign/continue_sign/UK/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    uploadFileDocuSignUk(data: FormData, cookies: Cookies) {
        return instence.post<unknown, { data: { link: string } }>(`/docusign/sign_document_uk/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    uploadFileDocuSign(data: FormData, cookies: Cookies) {
        return instence.post<unknown, { data: string }>(`/docusign/sign_document_us/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    confirmLinkDocuSign(user_id: string, user_token: string, country_code: string, event: string, cookies: Cookies) {
        return instence.get<{ answer: string }>(`/users/docusign_final/?user_id=${user_id}&user_token=${user_token}&country_code=${country_code}&event=${event}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    registerFirstStep(firstName: string, lastName: string, email: string) {
        return instence.post<unknown, RegisterFirstStepAxiosResponse>("users/", {
            first_name: firstName,
            last_name: lastName,
            email,
        });
    },
    setPassword(id: string, token: string, password: string) {
        return instence.post<unknown, { data: { [key: string]: string } }>(`/users/set/password/${id}/${token}/`, {
            password,
        });
    },
    resetPassword(id: string, token: string, password: string) {
        return instence.post<unknown, ResetPasswordAxiosResponse>(`/users/reset/password/${id}/${token}/`, {
            password,
        });
    },
    registerThirdStep(
        id: string,
        token: string,
        companyName: string,
        name: string,
        subdomain: string,
        adress: string,
        postalCode: string,
        city: string,
        state: string,
        country: string,
        phone: string | undefined,
        stripe_pm_id: string,
        affiliate_program_id: string | null
    ) {
        return instence.post<unknown, AxiosResponseTenantRegistration>(`tenants/tenants-manipulator/${id}/${token ? token + "/" : ""}`, {
            name: companyName,
            contact_name: name,
            slug: subdomain,
            billing_line1: adress,
            billing_postal_code: postalCode,
            billing_city: city,
            billing_state: state,
            billing_country: country,
            company_phone_number: phone,
            stripe_pm_id: stripe_pm_id,
            affiliate_program_id,
        });
    },
    registerPortalComplete(
        id: string,
        token: string,
        companyName: string,
        name: string,
        subdomain: string,
        adress: string,
        postalCode: string,
        city: string,
        state: string,
        country: string
    ) {
        return instence.post(`/tenants/portal/${id}/${token ? token + "/" : ""}`, {
            name: companyName,
            contact_name: name,
            slug: subdomain,
            billing_line1: adress,
            billing_postal_code: postalCode,
            billing_city: city,
            billing_state: state,
            billing_country: country,
        });
    },
    confirmEmail(id: string, token: string) {
        return instence.get("/users/activate/" + id + "/" + token + "/");
    },
    getToken(email: string, password: string) {
        return instence.post<unknown, GetTokenAxiosResponseType>("/token/obtain/", {
            email,
            password,
        });
    },
    refresh(refresh: string) {
        return instence.post<unknown, RefreshAxiosResponse>("/token/refresh/", {
            refresh,
        });
    },
    onSendEmailToReset(email: string) {
        return instence.post("/users/reset/password/request/", {
            email,
        });
    },
    getFreightForwardersList(cookies: Cookies) {
        return instence.get<FreightForwarderApproved>("/tenants/activate/", {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    approveFreightForwarder(id: string, status: boolean, cookies: Cookies) {
        return instence.post(
            `/tenants/activate/`,
            { tenant_id: id, activate: status },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    checkAffiliateCodeSignUp(code: string) {
        return instence.get(`/affiliate/code/check/${code}/?is_signup`);
    },
    getFreightForwardersListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instence.get<FreightForwarderApproved>(`/tenants/activate/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getFreightForwardersApprovedList(cookies: Cookies) {
        return instence.get<unknown, FreightForwarderApprovedAxiosResponseType>("/tenants/portals-tenants-list/", {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getFreightForwardersApprovedListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instence.get<unknown, FreightForwarderApprovedAxiosResponseType>(
            `/tenants/portals-tenants-list/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
