import { StoredAxiosResponseType } from "../redux/types/StoredTypes/stored.types";
import { instence } from "./authAPI";
import Cookies from "universal-cookie";

export const storedProduct = {
    getStoredProduct(cookies: Cookies, country_code?: string) {
        return instence.get<unknown, StoredAxiosResponseType>(
            `/shipments/stored/dashboard/?${country_code && country_code !== "ALL" ? `integration__country_code=${country_code}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getStoredProductAll(cookies: Cookies, items: number, offset: number, search: string, country_code?: string, brand?: string) {
        return instence.get<unknown, StoredAxiosResponseType>(
            `/shipments/stored/dashboard/?limit=${items ? items : 10}&offset=${offset}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${country_code && country_code !== "ALL" ? `&integration__country_code=${country_code}` : ""}${brand ? `&brand=${window.encodeURIComponent(brand)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
