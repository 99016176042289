import {
    IncomingAxiosResponseType,
    IncomingIntegrationResponseType,
    OutgoingAxiosResponseType,
    PrepareToShip,
    PrepareToShipResult,
    TBulkCommercialInvoiceItem,
} from "../redux/types/IncomingTypes/incoming.types";
import { TGenericRequestFormat } from "../redux/types/common/common.types";
import { instence } from "./authAPI";
import Cookies from "universal-cookie";

export const incomingProduct = {
    getIncomingProduct(cookies: Cookies, country_code?: string) {
        return instence.get<unknown, IncomingAxiosResponseType>(
            `/shipments/incoming-shipments/${country_code ? `?shipment_items__integration__country_code=${country_code}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getIncomingProductAll(cookies: Cookies, items: number, offset: number, searchTerm: string, country_code?: string, brand?: string) {
        return instence.get<unknown, IncomingAxiosResponseType>(
            `/shipments/incoming-shipments/?limit=${items}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}${country_code ? `&shipment_items__integration__country_code=${country_code}` : ""}${brand ? `&brand=${window.encodeURIComponent(brand)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    deleteIncomingProduct(cookies: Cookies, id: string) {
        return instence.delete(`/shipments/incoming-shipments/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPrepareToShip(cookies: Cookies, integrationId: string, searchTerm: string, items: number, offset: number) {
        return instence.get<PrepareToShip>(
            `/shipments/prepare-to-ship/?integration_id=${integrationId}&limit=${items ? items : 20}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postPrepareToShip(cookies: Cookies, data: FormData) {
        return instence.post(`/shipments/prepare-to-ship/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
                "Content-Type": "multipart/form-data",
            },
        });
    },
    getIncomingIntegrations(cookies: Cookies) {
        return instence.get<unknown, { data: IncomingIntegrationResponseType }>(`/shipments/available_integrations_to_ship/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkCommercialInvoiceProducts(cookies: Cookies, integrationId: string, searchValue: string, items: number, offset: number) {
        return instence.get<TGenericRequestFormat<TBulkCommercialInvoiceItem>>(
            `/shipments/upload_commercial_invoice/?integration_id=${integrationId}&limit=${items || 20}&offset=${offset}${searchValue ? `&search=${window.encodeURIComponent(searchValue)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postBulkCommercialInvoice(cookies: Cookies, formData: FormData) {
        return instence.post<TGenericRequestFormat<TBulkCommercialInvoiceItem>>(`/shipments/upload_commercial_invoice/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkSkuInboundTemplate(cookies: Cookies, integrationId: string) {
        return instence.get<string>(`/shipments/order-to-prep-file/?integration_id=${integrationId}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postBulkSkuInboundTemplate(cookies: Cookies, integrationId: string, formData: FormData) {
        return instence.post<PrepareToShipResult[]>(`/shipments/order-to-prep-file/?integration_id=${integrationId}`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const outgoingProduct = {
    getOutgoingProduct(cookies: Cookies, country_code?: string) {
        return instence.get<unknown, OutgoingAxiosResponseType>(`/shipments/outgoing-shipments/?${country_code ? `integration__country_code=${country_code}` : ""}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOutgoingProductAll(cookies: Cookies, items: number, offset: number, searchTerm: string, country_code?: string, brand?: string) {
        return instence.get<unknown, OutgoingAxiosResponseType>(
            `/shipments/outgoing-shipments/?limit=${items}&offset=${offset}${country_code ? `&integration__country_code=${country_code}` : ""}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}${brand ? `&brand=${window.encodeURIComponent(brand)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
