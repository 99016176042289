import axios from "axios";
import i18n from "i18next";
import Cookies from "universal-cookie";
import LanguageDetector from "i18next-browser-languagedetector";

import { jwtDecode } from "jwt-decode";

import { amazon } from "../api/amazonAPI";
import { loginAPI } from "../api/loginAPI";
import { registerAPI } from "../api/registerAPI";
import { notifications } from "../api/notificationAPI";
import { languageChange, landingPageAPI } from "../api/authAPI";

import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_ZH } from "../translations/zh/translations";
import { TRANSLATIONS_EN } from "../translations/en/translations";

import {
    ActionTypes,
    CalculatorDataType,
    FreightForwarderType,
    NotificationCountType,
    NotificationType,
    SetAmazonUrlType,
    SetAuthLoadingType,
    SetCalculatorDataType,
    SetCompleteStepType,
    SetConfirmEmailStepType,
    SetErrorType,
    SetFBAListType,
    SetFirstStepType,
    SetFreightForwardersListType,
    SetLanguageType,
    SetLoadingType,
    SetLogoutType,
    SetNotificationCountType,
    SetNotificationType,
    SetRegisterPortalCompleteType,
    SetSecondStepType,
    SetThirdStepType,
    SetUserType,
    SetWarehouseType,
    UserType,
    ThunkType,
    RequestFBAListData,
    JWTDecodeType,
    CardPaymentMethodType,
    SetFreightForwardersListAllType,
    SetFreightForwardersApprovedListType,
    SetFreightForwardersApprovedListAllType,
    FbaListDataType,
    SetErrorObjectType,
    AffiliateCodeReturnType,
    AmazonUrlsType,
    AmazonLinkAxiosResponse,
    SetDocusignContinueDataType,
    SetDocusignLoadingType,
    TPostContactSalesData,
} from "./types/AuthTypes/auth.types";
import { tenantList } from "../api/warehouseAPI";
import { BrokerApproveItem } from "./types/PortalTypes/portal.types";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
            zh: {
                translation: TRANSLATIONS_ZH,
            },
        },
    });

export const SET_USER = "SET_USER";
export const LOG_OUT = "LOG_OUT";
export const SET_LOADING = "SET_LOADING";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_FIRST_STEP = "SET_FIRST_STEP";
export const SET_SECOND_STEP = "SET_SECOND_STEP";
export const SET_THIRD_STEP = "SET_THIRD_STEP";
export const SET_COMPLETE_STEP = "SET_COMPLETE_STEP";
export const SET_CONFIRM_EMAIL_STEP = "SET_CONFIRM_EMAIL_STEP";
export const SET_WAREHOUSE = "SET_WAREHOUSE";
export const SET_AMAZON_URL = "SET_AMAZON_URL";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const SET_FBA_LIST = "SET_FBA_LIST";
export const SET_CALCULATED_DATA = "SET_CALCULATED_DATA";
export const SET_REGISTER_PORTAL_COMPLETE = "SET_REGISTER_PORTAL_COMPLETE";
export const SET_FREIGHT_FORWARDERS_LIST = "SET_FREIGHT_FORWARDERS_LIST";
export const SET_FREIGHT_FORWARDERS_LIST_ALL = "SET_FREIGHT_FORWARDERS_LIST_ALL";
export const SET_FREIGHT_FORWARDERS_APPROVED_LIST = "SET_FREIGHT_FORWARDERS_APPROVED_LIST";
export const SET_FREIGHT_FORWARDERS_APPROVED_LIST_ALL = "SET_FREIGHT_FORWARDERS_APPROVED_LIST_ALL";
export const SET_DOCU_SIGN_STEP = "SET_DOCU_SIGN_STEP";
export const SET_DOCUSIGN_CONTINUE_DATA = "SET_DOCUSIGN_CONTINUE_DATA";
export const SET_DOCUSIGN_LOADING = "SET_DOCUSIGN_LOADING";

type InitialAuthStateType = {
    user: null | UserType;
    isAuth: boolean;
    role: null | string;
    isLoading: boolean;
    isAuthLoading: boolean;
    error: null | string | number;
    registerFirstStep: boolean;
    registerSecondStep: boolean;
    registerThirdStep: boolean;
    registerPortalComplete: boolean;
    registerComplete: boolean;
    checkEmail: boolean;
    confirmEmail: boolean;
    cookies: Cookies;
    registerError: null | string | number | SetErrorObjectType;
    tenantType: null | string;
    language: string;
    notifications: NotificationType[];
    newNotificationsCount: null | NotificationCountType;
    fbaList: null | FbaListDataType[];
    calculatedData: null | CalculatorDataType;
    freightForwardersList: null | FreightForwarderType[];
    freightForwardersListAll: null | FreightForwarderType[];
    freightForwardersApprovedList: null | FreightForwarderType[]; // change
    freightForwardersApprovedListAll: null | FreightForwarderType[]; // change
    docusignContinueData: null | BrokerApproveItem;
    isDocusignLoading: boolean;
};

let initialState: InitialAuthStateType = {
    user: null,
    isAuth: false,
    role: null,
    isLoading: false,
    isAuthLoading: true,
    error: null,
    registerFirstStep: true,
    registerSecondStep: false,
    registerThirdStep: false,
    registerPortalComplete: false,
    registerComplete: false,
    checkEmail: false,
    confirmEmail: false,
    cookies: new Cookies(),
    registerError: null,
    tenantType: null,
    language: "zh",
    notifications: [],
    newNotificationsCount: null,
    fbaList: null,
    calculatedData: null,
    freightForwardersList: null,
    freightForwardersListAll: null,
    freightForwardersApprovedList: null,
    freightForwardersApprovedListAll: null,
    docusignContinueData: null,
    isDocusignLoading: false,
};

const authReducer = (state = initialState, action: ActionTypes): InitialAuthStateType => {
    switch (action.type) {
        case SET_DOCUSIGN_LOADING: {
            return {
                ...state,
                isDocusignLoading: action.data,
            };
        }
        case SET_DOCUSIGN_CONTINUE_DATA: {
            return {
                ...state,
                docusignContinueData: action.data,
            };
        }
        case SET_CALCULATED_DATA: {
            return {
                ...state,
                calculatedData: action.data,
            };
        }
        case SET_FBA_LIST: {
            return {
                ...state,
                fbaList: action.data,
            };
        }
        case SET_NOTIFICATION_COUNT: {
            return {
                ...state,
                newNotificationsCount: action.data,
            };
        }
        case SET_NOTIFICATION: {
            return {
                ...state,
                notifications: action.data,
            };
        }
        case SET_USER: {
            return {
                ...state,
                user: action.data,
                isAuth: true,
                role: action.role,
                isAuthLoading: false,
            };
        }
        case SET_WAREHOUSE: {
            return {
                ...state,
                tenantType: action.data,
                isAuthLoading: false,
            };
        }
        case SET_FIRST_STEP: {
            return {
                ...state,
                registerFirstStep: true,
                registerSecondStep: false,
                registerThirdStep: false,
                isLoading: false,
            };
        }
        case SET_SECOND_STEP: {
            return {
                ...state,
                registerFirstStep: false,
                registerSecondStep: false,
                registerThirdStep: false,
                checkEmail: true,
                isLoading: false,
            };
        }
        case SET_CONFIRM_EMAIL_STEP: {
            return {
                ...state,
                registerFirstStep: false,
                registerSecondStep: true,
                registerThirdStep: false,
                checkEmail: false,
                confirmEmail: true,
                isLoading: false,
            };
        }
        case SET_THIRD_STEP: {
            return {
                ...state,
                registerFirstStep: false,
                registerSecondStep: false,
                registerThirdStep: true,
                isLoading: false,
            };
        }
        case SET_REGISTER_PORTAL_COMPLETE: {
            return {
                ...state,
                registerFirstStep: false,
                registerSecondStep: false,
                registerThirdStep: false,
                registerPortalComplete: true,
                isLoading: false,
            };
        }
        case SET_COMPLETE_STEP: {
            return {
                ...state,
                registerFirstStep: false,
                registerSecondStep: false,
                registerThirdStep: false,
                registerComplete: true,
                isLoading: false,
            };
        }
        case SET_FREIGHT_FORWARDERS_LIST: {
            return {
                ...state,
                freightForwardersList: action.data,
            };
        }
        case SET_FREIGHT_FORWARDERS_LIST_ALL: {
            return {
                ...state,
                freightForwardersListAll: action.data,
            };
        }
        case SET_FREIGHT_FORWARDERS_APPROVED_LIST: {
            return {
                ...state,
                freightForwardersApprovedList: action.data,
            };
        }
        case SET_FREIGHT_FORWARDERS_APPROVED_LIST_ALL: {
            return {
                ...state,
                freightForwardersApprovedListAll: action.data,
            };
        }
        case LOG_OUT: {
            return {
                ...state,
                user: null,
                isAuth: false,
                isLoading: false,
                isAuthLoading: false,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_AUTH_LOADING: {
            return {
                ...state,
                isAuthLoading: action.data,
            };
        }
        case SET_ERROR: {
            return {
                ...state,
                registerError: action.data,
                isLoading: false,
            };
        }
        case SET_LANGUAGE: {
            return {
                ...state,
                language: action.data,
            };
        }
        default:
            return state;
    }
};
export const SetCalculatedData = (data: CalculatorDataType): SetCalculatorDataType => ({
    type: SET_CALCULATED_DATA,
    data,
});
export const SetFBAList = (data: FbaListDataType[]): SetFBAListType => ({
    type: SET_FBA_LIST,
    data,
});

export const SetUser = (user: null | UserType, role: string | null): SetUserType => ({
    type: SET_USER,
    data: user,
    role,
});
export const SetNotificationCount = (data: NotificationCountType): SetNotificationCountType => ({
    type: SET_NOTIFICATION_COUNT,
    data,
});
export const SetNotification = (data: NotificationType[]): SetNotificationType => ({
    type: SET_NOTIFICATION,
    data,
});
export const SetAmazonUrl = (data: AmazonUrlsType[]): SetAmazonUrlType => ({
    type: SET_AMAZON_URL,
    data,
});

export const SetFirstStep = (): SetFirstStepType => ({
    type: SET_FIRST_STEP,
});
export const SetConfirmEmail = (): SetConfirmEmailStepType => ({
    type: SET_CONFIRM_EMAIL_STEP,
});
export const SetSecondStep = (): SetSecondStepType => ({
    type: SET_SECOND_STEP,
});
export const SetThirdStep = (): SetThirdStepType => ({
    type: SET_THIRD_STEP,
});
export const SetRegisterPortalComplete = (): SetRegisterPortalCompleteType => ({
    type: SET_REGISTER_PORTAL_COMPLETE,
});
export const SetComplete = (): SetCompleteStepType => ({
    type: SET_COMPLETE_STEP,
});
export const SetLanguage = (data: string): SetLanguageType => ({
    type: SET_LANGUAGE,
    data,
});
export const LogOut = (): SetLogoutType => ({
    type: LOG_OUT,
});
export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});
export const SetAuthLoading = (loading: boolean): SetAuthLoadingType => ({
    type: SET_AUTH_LOADING,
    data: loading,
});
export const SetError = (error: null | string | number | SetErrorObjectType): SetErrorType => ({
    type: SET_ERROR,
    data: error,
});
export const SetWarehouse = (data: string): SetWarehouseType => ({
    type: SET_WAREHOUSE,
    data,
});
export const SetFreightForwardersList = (data: FreightForwarderType[]): SetFreightForwardersListType => ({
    type: SET_FREIGHT_FORWARDERS_LIST,
    data,
});
export const SetFreightForwardersListAll = (data: FreightForwarderType[]): SetFreightForwardersListAllType => ({
    type: SET_FREIGHT_FORWARDERS_LIST_ALL,
    data,
});
export const SetFreightForwardersApprovedList = (data: FreightForwarderType[]): SetFreightForwardersApprovedListType => ({
    type: SET_FREIGHT_FORWARDERS_APPROVED_LIST,
    data,
});
export const SetFreightForwardersApprovedListAll = (data: FreightForwarderType[]): SetFreightForwardersApprovedListAllType => ({
    type: SET_FREIGHT_FORWARDERS_APPROVED_LIST_ALL,
    data,
});
export const SetDocusignContinueData = (data: BrokerApproveItem): SetDocusignContinueDataType => ({
    type: SET_DOCUSIGN_CONTINUE_DATA,
    data,
});
export const SetDocusignLoading = (data: boolean): SetDocusignLoadingType => ({
    type: SET_DOCUSIGN_LOADING,
    data,
});

export const getFBAList = (): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await landingPageAPI.getFBAList();
            dispatch(SetFBAList(response.data));
            console.log(response.data);
        } catch (e) {
            console.log(e);
        }
    };
};

export const postFBAList = (market: string, data: RequestFBAListData, setStep: (value: string | number) => void): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await landingPageAPI.postFBAList(market, data);
            dispatch(SetCalculatedData(response.data));
            console.log(response.data);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                setStep("error");
                displayError(e.response.data, "Something went wrong during the calculation");
            }
        }
    };
};

export const getNotifications = (cookies: Cookies, limit: number): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await notifications.getNotifications(cookies, limit);
            dispatch(SetNotification(response.data.results));
            dispatch(
                SetNotificationCount({
                    quantity_unchecked: response.data.quantity_unchecked,
                    count: response.data.count,
                })
            );
            console.log(response.data);
        } catch (e) {
            console.log(e);
        }
    };
};

export const readNotifications = (cookies: Cookies, id: number[], limit: number): ThunkType => {
    return async (dispatch) => {
        try {
            await notifications.readNottification(cookies, id);
        } catch (e) {
            console.log(e);
        }
    };
};

export const readAllNotifications = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            await notifications.readAllNottification(cookies);
        } catch (e) {
            console.log(e);
        }
    };
};

export const readMoreNotifications = (cookies: Cookies, limit: number): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await notifications.getNotifications(cookies, limit);
            dispatch(SetNotification(response.data.results));
            dispatch(
                SetNotificationCount({
                    quantity_unchecked: response.data.quantity_unchecked,
                    count: response.data.count,
                })
            );
            let id: number[] = [];
            response.data.results.map((item) => {
                if (item.checked === false) {
                    id.push(item.id);
                }
                return null;
            });
            id.length > 0 && dispatch(readNotifications(cookies, id, limit + 5));
        } catch (e) {
            console.log(e);
        }
    };
};
export const deleteNottification = (cookies: Cookies, id: number): ThunkType => {
    return async (dispatch) => {
        try {
            await notifications.deleteNottification(cookies, id);
            dispatch(getNotifications(cookies, 10));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "Something went wrong while deleting a notification");
            }
        }
    };
};

export const getLanguage = (language: string, id: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            let languageName: string = "English";
            if (language === "en") {
                languageName = "English";
            } else if (language === "zh") {
                languageName = "Chinese";
            }
            await languageChange.getLanguage(languageName, id, cookies);
            i18n.changeLanguage(language);
            dispatch(SetLanguage(language));
        } catch (e) {
            console.log(e);
        }
    };
};

export const onLogin = (email: string, password: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await registerAPI.getToken(email, password);
            cookies.remove("firstStep", { path: "/", secure: true });
            cookies.remove("secondStep", { path: "/", secure: true });
            cookies.remove("docuSign", { path: "/", secure: true });
            cookies.remove("thirdStep", { path: "/", secure: true });
            cookies.remove("lastStep", { path: "/", secure: true });
            if (response.data.status === "Tenant create") {
                cookies.set("confirmId", response.data.uuid, {
                    path: "/",
                    secure: true,
                });
                cookies.set("confirmToken", response.data.token, {
                    path: "/",
                    secure: true,
                });
                cookies.set("thirdStep", true, { path: "/", secure: true });
                dispatch(SetError("Register tenant needed"));
            } else if (response.data && !response.data.access && !response.data.refresh) {
                alert(`You will be redirected to your subdomain ${response.data}. Please login again there.`);
                window.location.replace(`https://${response.data}/login`);
                dispatch(SetError(null));
            } else {
                cookies.set("token", response.data.access, {
                    path: "/",
                    secure: true,
                    domain: ".skudrop.com",
                });
                cookies.set("refresh", response.data.refresh, {
                    path: "/",
                    secure: true,
                    domain: ".skudrop.com",
                });
                await dispatch(Auth(cookies));
                dispatch(SetError(null));
            }
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 401) {
                    dispatch(SetError(e.response.status));
                }
            }
            dispatch(SetLoading(false));
        }
    };
};

export const Auth = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetAuthLoading(true));
            let userId = jwtDecode(cookies.get("token")) as JWTDecodeType;
            let isConfirmAmazon = !window.location.pathname.includes("/login-amazon");
            let firstStep = cookies.get("firstStep");
            let secondStep = cookies.get("secondStep");
            let docuSign = cookies.get("docuSign");
            let thirdStep = cookies.get("thirdStep");
            let lastStep = cookies.get("lastStep");
            let complete = cookies.get("complete");
            const id = userId.user_id;
            if (!firstStep && !secondStep && !thirdStep && !lastStep && !complete && !docuSign && isConfirmAmazon) {
                const response = await loginAPI.getUser(cookies, id);
                console.log(response);
                dispatch(getWarehouse(cookies));
                dispatch(SetUser(response.data, response.data.role));
                cookies.set("tenantName", response.data.schema_name, {
                    path: "/",
                    secure: true,
                    domain: ".skudrop.com",
                });
                if (response.data.language === "English") {
                    dispatch(SetLanguage("en"));
                    i18n.changeLanguage("en");
                } else if (response.data.language === "Chinese") {
                    dispatch(SetLanguage("zh"));
                    i18n.changeLanguage("zh");
                }
            }
            dispatch(SetAuthLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 401) {
                    dispatch(refreshToken(cookies));
                } else if (e.response && e.response.status === 503) {
                    dispatch(SetAuthLoading(false));
                }
            } else {
                dispatch(SetAuthLoading(false));
            }
        }
    };
};

export const resetPassword = (id: string, token: string, password: string, redirectToLogin: () => void): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            let response = await registerAPI.resetPassword(id, token, password);
            displaySuccess(response.data);
            redirectToLogin();
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    displayError(e.response.data["non_field_errors"][0]);
                    redirectToLogin();
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};

export const onSendEmailToReset = (email: string, setResetStatus: (string: string) => void, redirectToLogin: () => void): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            let response = await registerAPI.onSendEmailToReset(email);
            console.log(response.data);
            // dispatch(getToken(response.data.email,password,cookies))
            setResetStatus("second");
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    alert(e.response.data["non_field_errors"][0]);
                    redirectToLogin();
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};

export const getUser = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            let userId = jwtDecode(cookies.get("token")) as JWTDecodeType;
            const id = userId.user_id;
            const response = await loginAPI.getUser(cookies, id);
            console.log(response);
            dispatch(SetUser(response.data, response.data.role));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getWarehouse = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetAuthLoading(true));
            let response = await loginAPI.getWarehouse(cookies);
            console.log(response.data, "tenant type");
            dispatch(SetWarehouse(response.data.tenant_type));
            dispatch(SetAuthLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    dispatch(SetError(e.response.status));
                }
            }
        }
    };
};

export const registerFirstStep = (firstName: string, lastName: string, email: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const res = await registerAPI.registerFirstStep(firstName, lastName, email);
            if (res.data.status) {
                if (res.data.status === "Email wasn't confirmed, send email one more time") {
                    dispatch(SetError("Reactivate email"));
                } else if (res.data.status === "Email was confirmed, password wasn't set up. Send email again") {
                    dispatch(SetError("Reactivate email and set password"));
                }
            }
            dispatch(SetSecondStep());
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    dispatch(SetError(e.response.data));
                    if (e.response.data["non_field_errors"] && e.response.data["non_field_errors"][0] === "New tnant must be created from public schema") {
                        if (process.env.REACT_APP_PUBLIC_URL) {
                            window.location.href = process.env.REACT_APP_PUBLIC_URL;
                        }
                    } else if (e.response.data["status"] === "INVALID_URL_REGISTRATION") {
                        console.log("in invalid url");
                        alert(`You trying to register from invalid domain, you will be redirected to main domain.`);
                        window.location.replace(`https://${e.response.data["data"]}/register`);
                    } else if (e.response.data[0] === "User already exist.") {
                        dispatch(SetError({ email: e.response.data[0] }));
                    }
                }
            }
        }
    };
};

export const confirmEmail = (id: string, token: string, redirectToRegister: () => void, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            let response = await registerAPI.confirmEmail(id, token);
            if (response.data) {
                dispatch(SetConfirmEmail());
                cookies.set("secondStep", true, {
                    path: "/",
                    secure: true,
                });
            }
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    alert(e.response.data);
                    redirectToRegister();
                }
            }
        }
    };
};

export const confirmAmazon = (
    spapi_oauth_code: string,
    state: string,
    selling_partner_id: string,
    showEditNameModal: (data: AmazonLinkAxiosResponse) => void,
    redirectToDashboard: () => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await amazon.confirmAmazon(spapi_oauth_code, state, selling_partner_id);
            showEditNameModal(response.data);
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            dispatch(SetLoading(false));
            alert("Sorry, this integration already added");
            redirectToDashboard();
        }
    };
};

export const registerSecondStep = (id: string, token: string, password: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            let response = await registerAPI.setPassword(id, token, password);
            cookies.remove("secondStep", { path: "/", secure: true });
            cookies.remove("confirmToken", { path: "/", secure: true });
            cookies.set("thirdStep", true, {
                path: "/",
                secure: true,
            });
            console.log(response.data);
            dispatch(SetThirdStep());
            // dispatch(getToken(response.data.email,password,cookies))
        } catch (e) {
            console.log(e);
        }
    };
};

export const uploadFileDocuSign = (data: FormData, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetDocusignLoading(true));
            let response = await registerAPI.uploadFileDocuSign(data, cookies);

            cookies.set("docusignPageRedirect", window.location.pathname, {
                path: "/",
                secure: true,
                domain: ".skudrop.com",
            });

            window.location.href = response.data;
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetDocusignLoading(false));
                displayError(e.response.data, "Something went wrong during DocuSign processing");
            }
        }
    };
};

export const confirmLinkDocuSign = (user_id: string, user_token: string, country_code: string, event: string, onShowModal: () => void, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            let response = await registerAPI.confirmLinkDocuSign(user_id, user_token, country_code, event, cookies);
            console.log(response.data);
            if (response.data.answer === "OK") {
                onShowModal();
                dispatch(getUser(cookies));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const getToken = (email: string, password: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            let response = await registerAPI.getToken(email, password);
            console.log(response.data);
            cookies.set("token", response.data.access, {
                path: "/",
                secure: true,
            });
            cookies.set("refresh", response.data.refresh, {
                path: "/",
                secure: true,
            });
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const registerThirdStep = (
    id: string,
    token: string,
    companyName: string,
    name: string,
    subdomain: string,
    adress: string,
    postalCode: string,
    city: string,
    state: string,
    country: string,
    cookies: Cookies,
    phone: string | undefined,
    payload: CardPaymentMethodType,
    affiliate_program_id: string | null,
    handleError: (error: any) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const stripe_pm_id = payload?.paymentMethod?.id;
            const response = await registerAPI.registerThirdStep(
                id,
                token,
                companyName,
                name,
                subdomain,
                adress,
                postalCode,
                city,
                state,
                country,
                phone,
                stripe_pm_id,
                affiliate_program_id
            );

            cookies.set("tenant_id", response.data.id, {
                path: "/",
                secure: true,
                domain: ".skudrop.com",
            });
            dispatch(SetComplete());
            cookies.remove("thirdStep", { path: "/", secure: true });
            cookies.set("complete", true, {
                path: "/",
                secure: true,
            });

            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response && (e.response.status === 400 || e.response.status === 404)) {
                    handleError(e.response.data);
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};

type OptionsError = {
    type: string;
    message: string;
};

export const checkAffiliateCodeSignUp = (
    code: string,
    setAffiliate: (value: AffiliateCodeReturnType) => void,
    setError1: (name: "companyName" | "name" | "subdomain" | "adress" | "postalCode" | "city" | "state" | "country" | "code", value: OptionsError | {}) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            let response = await registerAPI.checkAffiliateCodeSignUp(code);
            console.log(response.data);
            setAffiliate(response.data);
            setError1("code", {});
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response && (e.response.status === 404 || e.response.status === 400)) {
                    setError1("code", { type: "custom", message: "Invalid Code" });
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};

export const registerPortalThirdStep = (
    id: string,
    token: string,
    companyName: string,
    name: string,
    subdomain: string,
    adress: string,
    postalCode: string,
    city: string,
    state: string,
    country: string,
    cookies: Cookies
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            console.log("here");
            const response = await registerAPI.registerPortalComplete(id, token, companyName, name, subdomain, adress, postalCode, city, state, country);
            if (response) {
                dispatch(SetRegisterPortalComplete());
                cookies.remove("thirdStep", { path: "/", secure: true });
            }
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getAmazonUrl = (confirmId: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            let response = await amazon.getAmazonUrl(confirmId, cookies.get("tenant_id"));
            console.log(response.data);

            dispatch(
                SetAmazonUrl([
                    { name: "UK", link: response.data.UK },
                    { name: "US", link: response.data.US },
                ])
            );
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const registerComplete = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            cookies.remove("complete", { path: "/", secure: true });
            cookies.remove("slug", { path: "/", secure: true });
        } catch (e) {
            console.log(e);
        }
    };
};

export const onLogOut = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            cookies.remove("token", { path: "/", secure: true, domain: ".skudrop.com" });
            cookies.remove("refresh", { path: "/", secure: true, domain: ".skudrop.com" });
            cookies.remove("csrftoken", { path: "/", secure: true });
            dispatch(LogOut());
        } catch (e) {
            console.log(e);
        }
    };
};

export const refreshToken = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetAuthLoading(true));
            const refresh = cookies.get("refresh");
            const response = await registerAPI.refresh(refresh);
            cookies.set("token", response.data.access, {
                path: "/",
                secure: true,
                domain: ".skudrop.com",
            });
            dispatch(Auth(cookies));
        } catch (e) {
            dispatch(onLogOut(cookies));
        }
    };
};

export const getFreightForwardersList = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await registerAPI.getFreightForwardersList(cookies);
            dispatch(SetFreightForwardersList(response.data.results));
            console.log(response.data);
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const approveFreightForwarder = (id: string, status: boolean, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await registerAPI.approveFreightForwarder(id, status, cookies);
            if (response.status === 200) {
                dispatch(getFreightForwardersList(cookies));
                dispatch(getFreightForwardersApprovedList(cookies));
            }
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong during the approval of the freight forwarder");
            }
        }
    };
};

export const getFreightForwardersApprovedList = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await registerAPI.getFreightForwardersApprovedList(cookies);
            dispatch(SetFreightForwardersApprovedList(response.data.results));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getFreightForwardersListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await registerAPI.getFreightForwardersListAll(cookies, items, offset, search);
            dispatch(SetFreightForwardersListAll(response.data.results));
            console.log(response.data);
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getFreightForwardersApprovedListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await registerAPI.getFreightForwardersApprovedListAll(cookies, items, offset, search);
            console.log(response.data);
            dispatch(SetFreightForwardersApprovedListAll(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteFreightForwarder = (cookies: Cookies, id: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await tenantList.deleteUserFromTennant(cookies, id);
            dispatch(getFreightForwardersApprovedList(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong when deleting a freight forwarder");
            }
        }
    };
};

export const uploadDocusignFileUk = (data: FormData, cookies: Cookies, onHide: () => void): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetDocusignLoading(true));
            let response = await registerAPI.uploadFileDocuSignUk(data, cookies);

            cookies.set("docusignPageRedirect", window.location.pathname, {
                path: "/",
                secure: true,
                domain: ".skudrop.com",
            });

            if (response.data.link) {
                window.location.href = response.data.link;
            } else {
                onHide();
                dispatch(Auth(cookies));
                dispatch(SetDocusignLoading(false));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetDocusignLoading(false));
                displayError(e.response.data, "Something went wrong during DocuSign processing");
            }
        }
    };
};

export const getDocusignContinueData = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await registerAPI.getDocusignContinueData(cookies);
            dispatch(SetDocusignContinueData(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const PostContactSales = (data: TPostContactSalesData): ThunkType => {
    return async () => {
        try {
            await landingPageAPI.postContactSales(data);
        } catch (e) {
            console.log(e);
        }
    };
};

export default authReducer;
