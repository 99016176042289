import { ResponseFBAListData, TPostContactSalesData } from "./../redux/types/AuthTypes/auth.types";
import axios from "axios";
import Cookies from "universal-cookie";
import { RequestFBAListData } from "../redux/types/AuthTypes/auth.types";

let LINK = /:\/\/([^\/]+)/.exec(window.location.href);
let API_URL;

if (LINK !== null && LINK.length > 0) {
    API_URL = `https://${LINK[1]}/api/`;
}

// if (window.location.href.includes(".skudrop")) {
//     API_URL = "https://localhost/";
// }
// else {
//     API_URL = "https://3.70.8.37/";
// }

export const instence = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

instence.interceptors.request.use(
    (config) => {
        const cookies = new Cookies();
        const csrftoken = cookies.get("csrftoken");

        if (csrftoken && config.headers) {
            config.headers["X-CSRFToken"] = csrftoken;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

instence.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 503 && error.response.data === "Service is currently undergoing maintenance. Please try again later.") {
                if (window.location.pathname !== "/maintenance") {
                    window.location.pathname = "maintenance";
                }
            }
        }
        return Promise.reject(error);
    }
);

export const languageChange = {
    getLanguage(language: string, id: string, cookies: Cookies) {
        return instence.patch(
            `users/update/language/${id}/`,
            {
                language: language,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const landingPageAPI = {
    getFBAList(market?: string) {
        return instence.get(`/rates/calculator/${market ? `${market}/` : "ALL/"}`);
    },
    postFBAList(market: string, data: RequestFBAListData) {
        return instence.post<unknown, ResponseFBAListData>(`/rates/calculator/${market}/`, data);
    },
    postContactSales(data: TPostContactSalesData) {
        return instence.post<unknown>(`/users/contact_form`, data);
    },
};
