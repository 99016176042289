import Cookies from "universal-cookie";
import { ShippedProductAxiosResponseType } from "../redux/types/SettingsTypes/settings.types";
import { instence } from "./authAPI";

export const shipedSKUProduct = {
    getShipedSKUProduct(cookies: Cookies, date_from?: string, date_to?: string) {
        return instence.get<unknown, ShippedProductAxiosResponseType>(`/shipments/user/dashboard/incoming-shipment-history/?date_from=${date_from}&date_to=${date_to}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getShipedSKUProductAll(cookies: Cookies, items: number, offset: number, searchTerm?: string, date_from?: string, date_to?: string) {
        return instence.get<unknown, ShippedProductAxiosResponseType>(
            `/shipments/user/dashboard/incoming-shipment-history/?limit=${items}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}&date_from=${date_from}&date_to=${date_to}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
