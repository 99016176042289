import axios from "axios";
import Cookies from "universal-cookie";

import {
    ActionChargingRateTypes,
    AirShippingRatesObjectType,
    ChargingRatesFileType,
    ChargingRatesType,
    FclcbmTruckRateObjectType,
    SetLoadingCustomerRatesType,
    SetLoadingType,
    SetRatesProductType,
    SetRatesTenantsListType,
    SetTenantPersonalRatesType,
    TGetRatesTenantList,
    TGetTenantPersonalRates,
    TPatchPersonalRatesData,
    TPatchTenantShippingCreditsData,
    ThunkType,
    Truck16cbmRateObjectType,
    Truck2k8kgRateObjectType,
    TruckCbmRateObjectType,
    TruckKgRateObjectType,
} from "./types/ChargingRatesTypes/ChargingRates.types";
import { affiliateAPI } from "../api/affiliateAPI";
import { ratesProductAPI } from "../api/ratesProductAPI";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";

export const SET_RATES_PRODUCT = "SET_RATES_PRODUCT";
export const SET_RATES_TENANT_LIST = "SET_RATES_TENANT_LIST";
export const SET_TENANT_PERSONAL_RATES = "SET_TENANT_PERSONAL_RATES";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_CUSTOMER_RATES = "SET_LOADING_CUSTOMER_RATES";

export type InitialStateType = {
    ratesProduct: null | ChargingRatesType;
    ratesTenantList: TGetRatesTenantList[] | null;
    tenantPersonalRates: TGetTenantPersonalRates | null;
    isLoading: boolean;
    isLoadingCustomerRates: boolean;
};

let initialState: InitialStateType = {
    ratesProduct: null,
    ratesTenantList: null,
    tenantPersonalRates: null,
    isLoading: false,
    isLoadingCustomerRates: false,
};

const ratesProductReducer = (state = initialState, action: ActionChargingRateTypes): InitialStateType => {
    switch (action.type) {
        case SET_RATES_PRODUCT: {
            return {
                ...state,
                ratesProduct: action.data,
            };
        }
        case SET_RATES_TENANT_LIST: {
            return {
                ...state,
                ratesTenantList: action.data,
            };
        }
        case SET_TENANT_PERSONAL_RATES: {
            return {
                ...state,
                tenantPersonalRates: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_LOADING_CUSTOMER_RATES: {
            return {
                ...state,
                isLoadingCustomerRates: action.data,
            };
        }
        default:
            return state;
    }
};

export const SetRatesProduct = (data: ChargingRatesType): SetRatesProductType => ({
    type: SET_RATES_PRODUCT,
    data,
});
export const SetRatesTenantsList = (data: TGetRatesTenantList[]): SetRatesTenantsListType => ({
    type: SET_RATES_TENANT_LIST,
    data,
});
export const SetTenantPersonalRates = (data: TGetTenantPersonalRates | null): SetTenantPersonalRatesType => ({
    type: SET_TENANT_PERSONAL_RATES,
    data,
});
export const SetLoading = (data: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data,
});
export const SetLoadingCustomerRates = (data: boolean): SetLoadingCustomerRatesType => ({
    type: SET_LOADING_CUSTOMER_RATES,
    data,
});

export const getRatesProduct = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await ratesProductAPI.getRatesProduct(cookies);
            dispatch(SetRatesProduct(response.data));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postRatesProduct = (
    cookies: Cookies,
    {
        fast_boat_12_99_kg_east,
        fast_boat_12_99_kg_mid,
        fast_boat_12_99_kg_west,
        fast_boat_gt_100_kg_east,
        fast_boat_gt_100_kg_mid,
        fast_boat_gt_100_kg_west,
        meidum_boat_12_99_kg_east,
        meidum_boat_12_99_kg_mid,
        meidum_boat_12_99_kg_west,
        meidum_boat_gt_100_kg_east,
        meidum_boat_gt_100_kg_mid,
        meidum_boat_gt_100_kg_west,
        slow_boat_12_99_kg_east,
        slow_boat_12_99_kg_mid,
        slow_boat_12_99_kg_west,
        slow_boat_gt_100_kg_east,
        slow_boat_gt_100_kg_mid,
        slow_boat_gt_100_kg_west,
        over_266_cm_rate,
        own_ff_prep_rate_over_50_cbm,
        remote_warehouse_surcharge,
        prep_rate,
        category_surcharge_rmb,
        rmb_conversation_rate,
        skudrop_margin,
        carton_range_22_40_kg,
        length_width_height_75_120_cm,
        truck_weight_gte_30,
        truck_kg_longest_size_120_150,
        truck_kg_longest_size_150_200,
        truck_cbm_longest_size_120_150,
        truck_cbm_longest_size_150_200,
        insurance_percent,
        fast_boat_cbm_east,
        fast_boat_cbm_mid,
        fast_boat_cbm_west,
        fast_boat_kg_ups_east,
        fast_boat_kg_ups_mid,
        fast_boat_kg_ups_west,
        meidum_boat_cbm_east,
        meidum_boat_cbm_mid,
        meidum_boat_cbm_west,
        meidum_boat_kg_ups_east,
        meidum_boat_kg_ups_mid,
        meidum_boat_kg_ups_west,
        slow_boat_cbm_east,
        slow_boat_cbm_mid,
        slow_boat_cbm_west,
        slow_boat_kg_ups_east,
        slow_boat_kg_ups_mid,
        slow_boat_kg_ups_west,
        min_cbm_value,
        min_kg_value,
        uk_additional_fee_for_weight,
        uk_kg_min_weight,
        uk_moq_min_weight,
        uk_mid_size_gte_70_extra,
        uk_size_gte_100_extra,
        uk_sizes_formula_extra,
        storage_fees_per_day_per_cbm,
        prep_rate_71_140_cbm,
        skudrop_margin_71_140_cbm,
        storage_fees_per_day_per_cbm_71_140_cbm,
    }: { [key: string]: string },
    fileName: null | ChargingRatesFileType,
    truck_cbm_rate: TruckCbmRateObjectType,
    truck_kg_rate: TruckKgRateObjectType,
    air_shipping_rates: AirShippingRatesObjectType,
    fclcbm_truck_rate: FclcbmTruckRateObjectType,
    truck_2k8kg_rate: Truck2k8kgRateObjectType,
    truck_16cbm_rate: Truck16cbmRateObjectType,
    ratesFile: null | ChargingRatesFileType,
    uk_kg_sea: { [key: string]: string | number | null },
    uk_kg_truck: { [key: string]: string | number | null }
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            let formData = new FormData();
            if (fileName || ratesFile) {
                fileName && formData.append("remote_wareouse_file", fileName.file);
                ratesFile && formData.append("rates_file", ratesFile.file);
                await ratesProductAPI.postRatesProductFile(cookies, formData);
            }
            let data = {
                fast_boat_12_99_kg_east,
                fast_boat_12_99_kg_mid,
                fast_boat_12_99_kg_west,
                fast_boat_gt_100_kg_east,
                fast_boat_gt_100_kg_mid,
                fast_boat_gt_100_kg_west,
                meidum_boat_12_99_kg_east,
                meidum_boat_12_99_kg_mid,
                meidum_boat_12_99_kg_west,
                meidum_boat_gt_100_kg_east,
                meidum_boat_gt_100_kg_mid,
                meidum_boat_gt_100_kg_west,
                slow_boat_12_99_kg_east,
                slow_boat_12_99_kg_mid,
                slow_boat_12_99_kg_west,
                slow_boat_gt_100_kg_east,
                slow_boat_gt_100_kg_mid,
                slow_boat_gt_100_kg_west,
                over_266_cm_rate,
                remote_warehouse_surcharge,
                prep_rate,
                category_surcharge_rmb,
                rmb_conversation_rate,
                skudrop_margin,
                own_ff_prep_rate_over_50_cbm,
                carton_range_22_40_kg,
                length_width_height_75_120_cm,
                truck_weight_gte_30,
                truck_kg_longest_size_120_150,
                truck_kg_longest_size_150_200,
                truck_cbm_longest_size_120_150,
                truck_cbm_longest_size_150_200,
                insurance_percent,
                fast_boat_cbm_east,
                fast_boat_cbm_mid,
                fast_boat_cbm_west,
                fast_boat_kg_ups_east,
                fast_boat_kg_ups_mid,
                fast_boat_kg_ups_west,
                meidum_boat_cbm_east,
                meidum_boat_cbm_mid,
                meidum_boat_cbm_west,
                meidum_boat_kg_ups_east,
                meidum_boat_kg_ups_mid,
                meidum_boat_kg_ups_west,
                slow_boat_cbm_east,
                slow_boat_cbm_mid,
                slow_boat_cbm_west,
                slow_boat_kg_ups_east,
                slow_boat_kg_ups_mid,
                slow_boat_kg_ups_west,
                min_cbm_value,
                min_kg_value,
                uk_additional_fee_for_weight,
                uk_kg_min_weight,
                uk_moq_min_weight,
                uk_mid_size_gte_70_extra,
                uk_size_gte_100_extra,
                uk_sizes_formula_extra,
                uk_kg_sea,
                uk_kg_truck,
                truck_cbm_rate: Object.keys(truck_cbm_rate).length > 0 ? truck_cbm_rate : null,
                truck_kg_rate: Object.keys(truck_cbm_rate).length > 0 ? truck_kg_rate : null,
                air_shipping_rates: Object.keys(air_shipping_rates).length > 0 ? air_shipping_rates : null,
                fclcbm_truck_rate: Object.keys(fclcbm_truck_rate).length > 0 ? fclcbm_truck_rate : null,
                truck_2k8kg_rate: Object.keys(truck_2k8kg_rate).length > 0 ? truck_2k8kg_rate : null,
                truck_16cbm_rate: Object.keys(truck_16cbm_rate).length > 0 ? truck_16cbm_rate : null,
                storage_fees_per_day_per_cbm,
                prep_rate_71_140_cbm,
                skudrop_margin_71_140_cbm,
                storage_fees_per_day_per_cbm_71_140_cbm,
            };

            !ratesFile && (await ratesProductAPI.postRatesProduct(cookies, data));
            dispatch(getRatesProduct(cookies)).then(() => {
                displaySuccess("Rates applied successfully");
            });
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data.message, "An error occurred while applying rates");
            }
        }
    };
};

export const getRatesTenantList = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingCustomerRates(true));
            const response = await ratesProductAPI.getRatesTenantList(cookies);
            dispatch(SetRatesTenantsList(response.data));
            dispatch(SetLoadingCustomerRates(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTenantPersonalRates = (cookies: Cookies, tenantId: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingCustomerRates(true));
            const response = await ratesProductAPI.getTenantPersonalRates(cookies, tenantId);
            dispatch(SetTenantPersonalRates(response.data));
            dispatch(SetLoadingCustomerRates(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchTenantPersonalRates = (cookies: Cookies, tenantId: string, ratesId: string, data: TPatchPersonalRatesData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingCustomerRates(true));
            const setRatesResponse = await ratesProductAPI.patchTenantPersonalRates(cookies, ratesId, data);
            if (setRatesResponse.status === 200) {
                const getRatesResponse = await ratesProductAPI.getTenantPersonalRates(cookies, tenantId);
                dispatch(SetTenantPersonalRates(getRatesResponse.data));
                dispatch(SetLoadingCustomerRates(false));
                displaySuccess("Changes applied successfully");
            }
        } catch (e) {
            dispatch(SetLoadingCustomerRates(false));
            displayError("An error occurred while changing rates");
        }
    };
};

export const patchTenantShippingCredits = (cookies: Cookies, tenantId: string, data: TPatchTenantShippingCreditsData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingCustomerRates(true));
            const addShippingBalanceResponse = await affiliateAPI.patchTenantShippingCredits(cookies, tenantId, data);
            if (addShippingBalanceResponse.status === 200) {
                dispatch(SetLoadingCustomerRates(false));
                displaySuccess("Shipping credits added successfully");
            }
        } catch (e) {
            dispatch(SetLoadingCustomerRates(false));
            displayError("An error occurred while adding shipping credits");
        }
    };
};

export default ratesProductReducer;
