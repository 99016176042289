import Cookies from "universal-cookie";
import {
    AffiliateCodeType,
    CheckAffiliateCodeAxiosResponse,
    DeleteReasonDataType,
    DeleteRequestObject,
    TGetPublicApiKeys,
    TPostPublicApiReqData,
    TPublicApiKey,
} from "../redux/types/SettingsTypes/settings.types";
import { instence } from "./authAPI";

export const userProfile = {
    activateAccount(cookies: Cookies, id: string, data: DeleteReasonDataType) {
        return instence.patch(`tenants/disable/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteAccountCheck(cookies: Cookies, id: string) {
        return instence.get(`tenants/disable/${id}/validate/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    disableAccount(cookies: Cookies, id: string, data: DeleteRequestObject) {
        return instence.patch(`tenants/disable/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteAccount(cookies: Cookies, id: string, data: DeleteRequestObject) {
        return instence.delete(`tenants/tenants-manipulator/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
            data,
        });
    },
    changePassword(cookies: Cookies, newPassword: string, currentPassword: string) {
        return instence.post(
            `users/change/password/`,
            {
                old_password: currentPassword,
                new_password: newPassword,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    addAffiliateCode(cookies: Cookies, id: string, tenantId: string) {
        return instence.patch(
            `/tenants/affiliate-code/${tenantId}/`,
            { affiliate_program_id: id },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getAffiliatesCodes(cookies: Cookies) {
        return instence.get<AffiliateCodeType>(`/affiliate/code/check/active_code/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    checkAffiliateCode(cookies: Cookies, code: string) {
        return instence.get<unknown, CheckAffiliateCodeAxiosResponse>(`/affiliate/code/check/${code}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    changeUserDetails(cookies: Cookies, userId: string, email: string, firstName: string, lastName: string) {
        return instence.patch(
            `/users/${userId}/`,
            {
                email: email,
                first_name: firstName,
                last_name: lastName,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getUserAdress(cookies: Cookies, userId: string) {
        return instence.get(`/tenants/tenants-billing-address/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    setUserAdress(
        cookies: Cookies,
        tenantID: string,
        billing_line1: string,
        billing_postal_code: string,
        billing_city: string,
        billing_state: string,
        billing_country: string,
        phone_number: string
    ) {
        return instence.patch(
            `/tenants/tenants-billing-address/${tenantID}/`,
            {
                billing_line1: billing_line1,
                billing_postal_code: billing_postal_code,
                billing_city: billing_city,
                billing_state: billing_state,
                billing_country: billing_country,
                company_phone_number: phone_number,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getPublicApiKeys(cookies: Cookies) {
        return instence.get<TGetPublicApiKeys>(`/tenants/public-keys/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCreatePublicApiKey(cookies: Cookies, data: TPostPublicApiReqData) {
        return instence.post<TPublicApiKey>(`/tenants/public-keys/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteApiKey(cookies: Cookies, id: string) {
        return instence.delete(`/tenants/public-keys/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchUpdatePublicApiKey(cookies: Cookies, id: string, key_expiration: string | undefined) {
        return instence.patch(
            `/tenants/public-keys/${id}/`,
            { key_expiration },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const PaymentMethodAPI = {
    onPaymentConfirm(cookies: Cookies, payment_intent: string, payment_intent_client_secret: string, redirect_status: string) {
        return instence.get(
            `/payments/stripe/payment/confirm/?payment_intent=${payment_intent}&payment_intent_client_secret=${payment_intent_client_secret}&redirect_status=${redirect_status}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    onStripeConfirm(cookies: Cookies, payment_intent: string, redirect_status: string) {
        return instence.get(`/payments/stripe/payment/confirm/?payment_intent=${payment_intent}&redirect_status=${redirect_status}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    onManualPayment(cookies: Cookies, id: string) {
        return instence.patch(
            `/payments/stripe/manual/pay/${id}/`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getPaymatMethod(cookies: Cookies) {
        return instence.get(`/payments/payment-method-operation/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    updatePaymantMethod(cookies: Cookies, stripe_pm_id: string) {
        return instence.post(
            `/payments/payment-method-operation/`,
            {
                stripe_pm_id: stripe_pm_id,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
