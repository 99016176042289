import axios from "axios";
import Cookies from "universal-cookie";

import {
    Factory,
    IncomingActionTypes,
    IncomingIntegration,
    IncomingShipmentsResult,
    IncomingThunkType,
    IncomingTotals,
    OutgoingShipmentsResult,
    PrepTotals,
    PrepareToShipResult,
    SetErrorIncomingProductType,
    SetFactoriesType,
    SetIncomingIntegrationsType,
    SetIncomingProductAllType,
    SetIncomingProductType,
    SetIncomingTotalsType,
    SetLoadingIntegrationsType,
    SetOutgoingProductAllType,
    SetOutgoingProductType,
    SetOutgoingTotalsType,
    TBulkCommercialInvoiceItem,
    TBulkCommercialInvoiceState,
    TLoadingStates,
    TPrepareToShipState,
    TSetBulkCommercialInvoiceProducts,
    TSetBulkSkuInboundTemplateProducts,
    TSetLoadingStates,
    TSetPrepareToShipProduct,
} from "./types/IncomingTypes/incoming.types";
import { displayError } from "../hooks/useErrorHandler";
import { SetLoadingType } from "./types/StoredTypes/stored.types";
import { incomingProduct, outgoingProduct } from "../api/incomingProductApi";
import { getBrandProduct } from "./addProductReducer";

export const SET_INCOMING_PRODUCT = "SET_INCOMING_PRODUCT";
export const SET_INCOMING_TOTAL = "SET_INCOMING_TOTAL";
export const SET_LOADING = "SET_LOADING";
export const SET_PREPARE_TO_SHIP_PRODUCT = "SET_PREPARE_TO_SHIP_PRODUCT";
export const SET_FACTORIES = "SET_FACTORIES";
export const SET_PREPARE_TO_SHIP_PRODUCT_NEW = "SET_PREPARE_TO_SHIP_PRODUCT_NEW";
export const SET_ERROR_INCOMING_PRODUCT = "SET_ERROR_INCOMING_PRODUCT";
export const SET_INCOMING_PRODUCT_ALL = "SET_INCOMING_PRODUCT_ALL";
export const SET_OUTGOING_PRODUCT_ALL = "SET_OUTGOING_PRODUCT_ALL";
export const SET_OUTGOING_PRODUCT = "SET_OUTGOING_PRODUCT";
export const SET_OUTGOING_TOTAL = "SET_OUTGOING_TOTAL";
export const SET_INCOMING_PRODUCT_DELETE = "SET_INCOMING_PRODUCT_DELETE";
export const SET_PRODUCT_REQUIRE_ACTION = "SET_PRODUCT_REQUIRE_ACTION";
export const SET_INCOMING_INTEGRATIONS = "SET_INCOMING_INTEGRATIONS";
export const SET_LOADING_INTEGRATIONS = "SET_LOADING_INTEGRATIONS";
export const SET_BULK_COMMERCIAL_INVOICE_PRODUCTS = "SET_BULK_COMMERCIAL_INVOICE_PRODUCTS";
export const SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS = "SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";

type InitialStateType = {
    incomingProduct: null | IncomingShipmentsResult[];
    incomingProductAll: null | IncomingShipmentsResult[];
    outgoingProduct: null | OutgoingShipmentsResult[];
    outgoingProductAll: null | OutgoingShipmentsResult[];
    incomingTotals: null | IncomingTotals;
    outgoingTotals: null | PrepTotals;
    prepareToShipProduct: null | TPrepareToShipState;
    isLoading: boolean;
    isErrorIncomingProduct: null | string | boolean;
    searchFactoryValue: null | Factory[];
    incomingIntegrations: null | IncomingIntegration[];
    isLoadingIntegrations: boolean;
    bulkCommercialInvoiceProducts: TBulkCommercialInvoiceState | null;
    bulkSkuInboundTemplateProducts: TPrepareToShipState | null;
    loadingStates: TLoadingStates;
};

let initialState: InitialStateType = {
    incomingProduct: null,
    incomingProductAll: null,
    outgoingProduct: null,
    outgoingProductAll: null,
    incomingTotals: null,
    outgoingTotals: null,
    prepareToShipProduct: null,
    isLoading: false,
    isErrorIncomingProduct: null,
    searchFactoryValue: null,
    incomingIntegrations: null,
    isLoadingIntegrations: false,
    bulkCommercialInvoiceProducts: null,
    bulkSkuInboundTemplateProducts: null,
    loadingStates: {
        isLoadingIncomingProducts: false,
        isLoadingOutgoingProducts: false,
        isLoadingBulkCommercialInvoice: false,
        isLoadingBulkSkuInboundModal: false,
    },
};

const incomingProductReducer = (state = initialState, action: IncomingActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_INCOMING_INTEGRATIONS: {
            return {
                ...state,
                incomingIntegrations: action.data,
            };
        }
        case SET_INCOMING_PRODUCT: {
            return {
                ...state,
                incomingProduct: action.data,
                isLoading: false,
            };
        }
        case SET_INCOMING_PRODUCT_ALL: {
            return {
                ...state,
                incomingProductAll: action.data,
                isLoading: false,
            };
        }
        case SET_OUTGOING_PRODUCT: {
            return {
                ...state,
                outgoingProduct: action.data,
                isLoading: false,
            };
        }
        case SET_OUTGOING_PRODUCT_ALL: {
            return {
                ...state,
                outgoingProductAll: action.data,
                isLoading: false,
            };
        }
        case SET_INCOMING_TOTAL: {
            return {
                ...state,
                incomingTotals: action.data,
                isLoading: false,
            };
        }
        case SET_OUTGOING_TOTAL: {
            return {
                ...state,
                outgoingTotals: action.data,
                isLoading: false,
            };
        }
        case SET_PREPARE_TO_SHIP_PRODUCT: {
            return {
                ...state,
                prepareToShipProduct: { ...state?.prepareToShipProduct, [action.integrationId]: action.data },
            };
        }
        case SET_FACTORIES: {
            return {
                ...state,
                searchFactoryValue: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_ERROR_INCOMING_PRODUCT: {
            return {
                ...state,
                isErrorIncomingProduct: action.data,
            };
        }
        case SET_LOADING_INTEGRATIONS: {
            return {
                ...state,
                isLoadingIntegrations: action.data,
            };
        }
        case SET_BULK_COMMERCIAL_INVOICE_PRODUCTS: {
            return {
                ...state,
                bulkCommercialInvoiceProducts: { ...state?.bulkCommercialInvoiceProducts, [action.integrationId]: action.data },
            };
        }
        case SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS: {
            return {
                ...state,
                bulkSkuInboundTemplateProducts: { ...state?.bulkSkuInboundTemplateProducts, [action.integrationId]: action.data },
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};

export const SetIncomingProduct = (data: IncomingShipmentsResult[]): SetIncomingProductType => ({
    type: SET_INCOMING_PRODUCT,
    data,
});
export const SetIncomingProductAll = (data: IncomingShipmentsResult[]): SetIncomingProductAllType => ({
    type: SET_INCOMING_PRODUCT_ALL,
    data,
});
export const SetOutgoingProduct = (data: OutgoingShipmentsResult[]): SetOutgoingProductType => ({
    type: SET_OUTGOING_PRODUCT,
    data,
});
export const SetOutgoingProductAll = (data: OutgoingShipmentsResult[]): SetOutgoingProductAllType => ({
    type: SET_OUTGOING_PRODUCT_ALL,
    data,
});
export const SetIncomingTotals = (data: IncomingTotals): SetIncomingTotalsType => ({
    type: SET_INCOMING_TOTAL,
    data,
});
export const SetOutgoingTotals = (data: PrepTotals): SetOutgoingTotalsType => ({
    type: SET_OUTGOING_TOTAL,
    data,
});
export const SetPrepareToShipProduct = (data: PrepareToShipResult[], integrationId: string): TSetPrepareToShipProduct => ({
    type: SET_PREPARE_TO_SHIP_PRODUCT,
    data,
    integrationId,
});
export const SetFactories = (data: Factory[]): SetFactoriesType => ({
    type: SET_FACTORIES,
    data,
});
export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});
export const SetErrorIncomingProduct = (error: null | boolean | string): SetErrorIncomingProductType => ({
    type: SET_ERROR_INCOMING_PRODUCT,
    data: error,
});
export const SetIncomingIntegrations = (data: IncomingIntegration[]): SetIncomingIntegrationsType => ({
    type: SET_INCOMING_INTEGRATIONS,
    data,
});
export const SetLoadingIntegrations = (loading: boolean): SetLoadingIntegrationsType => ({
    type: SET_LOADING_INTEGRATIONS,
    data: loading,
});
export const SetBulkCommercialInvoiceProducts = (data: TBulkCommercialInvoiceItem[], integrationId: string): TSetBulkCommercialInvoiceProducts => ({
    type: SET_BULK_COMMERCIAL_INVOICE_PRODUCTS,
    data,
    integrationId,
});

export const SetBulkSkuInboundTemplateProducts = (data: PrepareToShipResult[], integrationId: string): TSetBulkSkuInboundTemplateProducts => ({
    type: SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS,
    data,
    integrationId,
});

export const SetLoadingStates = (data: { [key in keyof TLoadingStates]?: boolean }): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const getIncomingProduct = (cookies: Cookies, country_code?: string): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingProducts: true }));
            const response = await incomingProduct.getIncomingProduct(cookies, country_code);
            dispatch(SetIncomingProduct(response.data.results));
            dispatch(SetIncomingTotals(response.data.incoming_totals));
            dispatch(SetLoadingStates({ isLoadingIncomingProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getIncomingProductAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    searchTerm: string,
    setProductAllNext: (value: boolean) => void,
    country_code?: string,
    brand?: string
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            const response = await incomingProduct.getIncomingProductAll(cookies, items, offset, searchTerm, country_code, brand);
            dispatch(SetIncomingProductAll(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOutgoingProduct = (cookies: Cookies, country_code?: string): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOutgoingProducts: true }));
            const response = await outgoingProduct.getOutgoingProduct(cookies, country_code);
            dispatch(SetOutgoingProduct(response.data.results));
            dispatch(SetOutgoingTotals(response.data.prep_totals));
            dispatch(SetLoadingStates({ isLoadingOutgoingProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getOutgoingProductAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    searchTerm: string,
    setProductAllNext: (value: boolean) => void,
    country_code?: string,
    brand?: string
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            const response = await outgoingProduct.getOutgoingProductAll(cookies, items, offset, searchTerm, country_code, brand);
            dispatch(SetOutgoingProductAll(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getPrepareToShipProduct = (
    cookies: Cookies,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            if (!offset) {
                dispatch(SetLoading(true));
            }
            dispatch(SetErrorIncomingProduct(null));
            const response = await incomingProduct.getPrepareToShip(cookies, integrationId, searchTerm, items, offset);
            setProductAllNext(!!response.data.next);
            dispatch(SetPrepareToShipProduct(response.data.results, integrationId));
            dispatch(SetFactories(response.data.factories));
            if (!offset) {
                dispatch(SetLoading(false));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteIncomingProduct = (cookies: Cookies, product: IncomingShipmentsResult, activeMarket: string | undefined): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingProducts: true }));
            await incomingProduct.deleteIncomingProduct(cookies, product.id);
            dispatch(getIncomingProduct(cookies, activeMarket));
            if (product.shipment_items[0].product_brand) {
                dispatch(getBrandProduct(cookies, product.shipment_items[0].product_brand));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingProducts: false }));
                displayError(e.response.data, "Something went wrong while deleting the product");
            }
        }
    };
};

export const postPrepareToShipProduct = (
    cookies: Cookies,
    data: FormData,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await incomingProduct.postPrepareToShip(cookies, data);
            if (response.data) {
                console.log(response.data);
                dispatch(SetErrorIncomingProduct(false));
                dispatch(getPrepareToShipProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));
            }
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispatch(getPrepareToShipProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));
                displayError(e.response?.data, "Something went wrong...");
            }
        }
    };
};

export const getIncomingIntegrations = (cookies: Cookies): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingIntegrations(true));
            const response = await incomingProduct.getIncomingIntegrations(cookies);
            dispatch(SetIncomingIntegrations(response.data.results));
            dispatch(SetLoadingIntegrations(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getBulkCommercialInvoiceProducts = (
    cookies: Cookies,
    integrationId: string,
    searchValue: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            const response = await incomingProduct.getBulkCommercialInvoiceProducts(cookies, integrationId, searchValue, items, offset);
            setProductAllNext(!!response.data.next);
            dispatch(SetBulkCommercialInvoiceProducts(response.data.results, integrationId));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postBulkCommercialInvoice = (
    cookies: Cookies,
    productsToProcess: TBulkCommercialInvoiceItem[],
    invoiceFile: File,
    integration: string,
    onSuccess: () => void,
    processUpdatedCommercialInvoice: (updatedProducts: PrepareToShipResult[]) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: true }));
            const formData = new FormData();

            formData.append("commercial_invoice", invoiceFile);

            productsToProcess.forEach((product) => {
                formData.append("product_ids", product.id);
            });

            await incomingProduct.postBulkCommercialInvoice(cookies, formData);

            const response = await incomingProduct.getPrepareToShip(cookies, integration, productsToProcess.map((item) => item.sku).join(","), 1000, 0);

            processUpdatedCommercialInvoice(response.data.results);

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getBulkSkuInboundTemplate = (cookies: Cookies, integrationId: string): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: true }));

            const response = await incomingProduct.getBulkSkuInboundTemplate(cookies, integrationId);

            window.open(response.data, "_blank");

            dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postBulkSkuInboundTemplate = (cookies: Cookies, integrationId: string, formData: FormData, setProductAllNext: (value: boolean) => void): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            dispatch(SetErrorIncomingProduct(null));

            const response1 = await incomingProduct.getPrepareToShip(cookies, integrationId, "", 20, 0);

            const response2 = await incomingProduct.postBulkSkuInboundTemplate(cookies, integrationId, formData);

            const productsToReceive = response2.data
                .filter((item) => !response1.data.results.some((obj) => obj.sku === item.sku))
                .map((item) => item.sku)
                .join(","); // find products difference between bulk sku inbound template products and prepare to ship products

            let response3;

            if (productsToReceive) {
                response3 = await incomingProduct.getPrepareToShip(cookies, integrationId, productsToReceive, 1000, 0);
            }

            setProductAllNext(!!response1.data.next);

            dispatch(SetPrepareToShipProduct([...(response3?.data?.results || []), ...response1.data.results], integrationId));
            dispatch(SetFactories(response1.data.factories));

            dispatch(SetBulkSkuInboundTemplateProducts(response2.data, integrationId));

            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(getPrepareToShipProduct(cookies, integrationId, "", 20, 0, setProductAllNext));
                displayError(e.response.data);
            }
        }
    };
};

export default incomingProductReducer;
