import { TPatchPersonalRatesData } from "./../redux/types/ChargingRatesTypes/ChargingRates.types";
import Cookies from "universal-cookie";
import { UpdateChargingRateObject } from "../redux/types/ChargingRatesTypes/ChargingRates.types";
import { instence } from "./authAPI";

export const ratesProductAPI = {
    getRatesProduct(cookies: Cookies) {
        return instence.get(`/rates/actions/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postRatesProductFile(cookies: Cookies, formData: FormData) {
        return instence.post(`/rates/files-upload/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
                "Content-Type": "multipart/form-data",
            },
        });
    },
    postRatesProduct(cookies: Cookies, data: UpdateChargingRateObject) {
        return instence.post(`/rates/actions/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getRatesTenantList(cookies: Cookies) {
        return instence.get(`/rates/tenant_list/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTenantPersonalRates(cookies: Cookies, tenantId: string) {
        return instence.get(`/rates/personal-rates/?tenant__id=${tenantId}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchTenantPersonalRates(cookies: Cookies, ratesId: string, data: TPatchPersonalRatesData) {
        return instence.patch(`/rates/personal-rates/${ratesId}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
