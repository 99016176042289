import { Tooltip as ReactTooltip } from "react-tooltip";
import { useState, useMemo, useEffect, FC } from "react";
import { useForm, UseFormTrigger } from "react-hook-form";
import { Col, Container, Button, Form, Row, InputGroup, Alert } from "react-bootstrap";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";

import { COUNTRIES } from "../../DashboardWarehouse/common/Constants";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";
import useErrorHandler, { displayError } from "../../../hooks/useErrorHandler";
import { AFFILIATE_PERIODS_RELATION } from "../../DashboardWarehouse/Affiliate/constants/variableConst";
import { AffiliateCodeReturnType, CardPaymentMethodType } from "../../../redux/types/AuthTypes/auth.types";
import { checkAffiliateCodeSignUp, registerPortalThirdStep, registerThirdStep } from "../../../redux/authReducer";

import { FaCreditCard } from "react-icons/fa";

import PhoneInput from "react-phone-input-2";
import validator from "validator";

import preloader from "../../../assets/video/preloader.webp";
import useResponsiveFontSize from "../../../useResponsiveFontSize";
import powered_by_stripe from "../../../assets/img/powered_by_stripe.png";

const CardNumberStile = () => {
    const fontSize = useResponsiveFontSize();

    const CardNumber = useMemo(
        () => ({
            placeholder: "Credit Card Number",
            style: {
                base: {
                    fontSize,
                    borderRadius: "4px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                        color: "#6c757d",
                    },
                },
                invalid: {
                    color: "#9e2146",
                },
            },
        }),
        [fontSize]
    );

    return CardNumber;
};

const CardCardExpiryStile = () => {
    const fontSize = useResponsiveFontSize();

    const CardExpiry = useMemo(
        () => ({
            placeholder: "Expiry date MM/YY",
            style: {
                base: {
                    fontSize,
                    borderRadius: "4px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                        color: "#6d6d6d",
                    },
                },
                invalid: {
                    color: "#9e2146",
                },
            },
        }),
        [fontSize]
    );

    return CardExpiry;
};

const CardCvcStile = () => {
    const fontSize = useResponsiveFontSize();

    const CardCvc = useMemo(
        () => ({
            placeholder: "CVV",
            style: {
                base: {
                    fontSize,
                    borderRadius: "4px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                        color: "#6c757d",
                    },
                },
                invalid: {
                    color: "#9e2146",
                },
            },
        }),
        [fontSize]
    );

    return CardCvc;
};

type SignUpThirdStepPropsType = {
    logo: string;
};

type ThirdStepSubmitData = {
    companyName: string;
    name: string;
    subdomain: string;
    adress: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    code: string | undefined;
    phone: string | undefined;
};

type ThirdStepPortalSubmitData = {
    companyName: string;
    name: string;
    subdomain: string;
    adress: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
};

const SignUpThirdStep: FC<SignUpThirdStepPropsType> = ({ logo }) => {
    const dispatch = useAppDispatch();
    const cookies = useAppSelector((state) => state.auth.cookies);
    const stripe = useStripe();
    const elements = useElements();
    const CardNumber = CardNumberStile();
    const CardExpiry = CardCardExpiryStile();
    const CardCvc = CardCvcStile();
    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const registerError = useAppSelector((state) => state.auth.registerError);
    const [phone, setPhone] = useState<string | undefined>();
    const [error, setError] = useState(true);
    const [isValidation, setValidation] = useState(true);
    const [isPortal, setIsPortal] = useState(false);
    const [affiliate_program_id, setAffiliate] = useState<AffiliateCodeReturnType | null>(null);
    const [reqError, setReqError, handleError] = useErrorHandler();

    const {
        register,
        handleSubmit,
        getValues,
        setError: setErrorForm,
        formState: { errors },
        trigger,
    } = useForm<ThirdStepSubmitData>({
        reValidateMode: "onChange",
    });

    const onAddCode = () => {
        let code = getValues("code");
        if (code) {
            dispatch(checkAffiliateCodeSignUp(code, setAffiliate, setErrorForm));
        } else {
            setErrorForm("code", { type: "custom", message: "Code is required" });
        }
    };

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        formState: { errors: errors1 },
        trigger: trigger1,
    } = useForm<ThirdStepPortalSubmitData>({
        reValidateMode: "onChange",
    });

    const onSubmit = async (data: ThirdStepSubmitData) => {
        if (!stripe || !elements) {
            return;
        }

        if (data.country === "Country Code") {
            setErrorForm("country", { type: "custom", message: "Country Code is required" });
        } else {
            const payload = (await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement)!,
            })) as CardPaymentMethodType;

            if (payload?.paymentMethod?.id) {
                if (isValidation === true) {
                    dispatch(
                        registerThirdStep(
                            cookies.get("confirmId"),
                            cookies.get("confirmToken"),
                            data.companyName,
                            data.name,
                            data.subdomain,
                            data.adress,
                            data.postalCode,
                            data.city,
                            data.state,
                            data.country,
                            cookies,
                            phone,
                            payload,
                            affiliate_program_id ? affiliate_program_id.id : null,
                            handleError
                        )
                    );
                    console.log(payload?.paymentMethod?.id);
                }
                if (isValidation === false) {
                    dispatch(
                        registerThirdStep(
                            cookies.get("confirmId"),
                            cookies.get("confirmToken"),
                            data.companyName,
                            data.name,
                            data.subdomain,
                            data.adress,
                            data.postalCode,
                            data.city,
                            data.state,
                            data.country,
                            cookies,
                            undefined,
                            payload,
                            affiliate_program_id ? affiliate_program_id.id : null,
                            handleError
                        )
                    );
                    console.log(payload?.paymentMethod?.id);
                }
            } else {
                displayError("Credit Card is required");
            }
        }
    };

    const onSubmitPortal = async (data: ThirdStepPortalSubmitData) => {
        if (data.country === "Country Code") {
            setErrorForm("country", { type: "custom", message: "Country Code is required" });
        } else {
            dispatch(
                registerPortalThirdStep(
                    cookies.get("confirmId"),
                    cookies.get("confirmToken"),
                    data.companyName,
                    data.name,
                    data.subdomain,
                    data.adress,
                    data.postalCode,
                    data.city,
                    data.state,
                    data.country,
                    cookies
                )
            );
        }
    };
    const onValidateSubDomain = (trigger: UseFormTrigger<ThirdStepSubmitData> | UseFormTrigger<ThirdStepPortalSubmitData>) => {
        trigger("subdomain");
    };

    useEffect(() => {
        if (window.location.hostname.includes("portal.")) {
            setIsPortal(true);
        } else {
            setIsPortal(false);
        }
    }, []);

    const handleChangePhone = (value: string) => {
        if (validator.isMobilePhone(value) === true) {
            setValidation(true);
        }
        if (validator.isMobilePhone(value) === false) {
            setValidation(false);
        }
        if (value.length === 0) {
            setValidation(true);
        }
        setPhone(value);
    };

    if (isLoading) {
        return (
            <Container className="block auth-form-container container-bg">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
                </Form>
            </Container>
        );
    }
    return (
        <Container className="auth-form-container p-0 container-bg mb-3">
            <Row className="mt-4">
                <Col>
                    {error && registerError === "Register tenant needed" && (
                        <Alert className="text-center" variant={"danger"} onClose={() => setError(false)} dismissible>
                            Please complete registration of your account.
                        </Alert>
                    )}
                </Col>
            </Row>
            {isPortal ? (
                <Form className="auth-form shadow-sm large-form mt-4 max-w-430px" onSubmit={handleSubmit1(onSubmitPortal)} noValidate>
                    <Row className="d-flex justify-content-center">
                        <img src={logo} className="skudrop-logo mb-4" alt="" />
                    </Row>
                    {reqError && (
                        <Alert className="text-center" variant={"danger"} onClose={() => setReqError(false)} dismissible>
                            {Array.isArray(reqError) ? reqError[0] : reqError}
                        </Alert>
                    )}
                    <Form.Group className="mb-3" controlId="formBasicFirstName">
                        <Form.Label className="font-bold mb-0">Company Details</Form.Label>
                        <Form.Control
                            className={errors1.companyName ? "py-1 px-2 input" : "py-1 px-2"}
                            type="text"
                            placeholder="Company Name"
                            {...register1("companyName", { required: { value: true, message: "Company Name required" } })}
                        />
                        {errors1.companyName && <p className="mb-0 error-message">{errors1.companyName.message}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicLastName">
                        <Form.Label className="font-bold mb-0">Contact Name</Form.Label>
                        <Form.Control
                            className={errors1.name ? "py-1 px-2 input" : "py-1 px-2"}
                            type="text"
                            placeholder="Name"
                            {...register1("name", { required: { value: true, message: "Name required" } })}
                        />
                        {errors1.name && <p className="mb-0 error-message">{errors1.name.message}</p>}
                        <Form.Label className="font-bold mt-2 mb-0 mt-3">SKUdrop Username</Form.Label>
                        <InputGroup>
                            <Form.Control
                                style={{ borderRight: "none" }}
                                onKeyUp={() => onValidateSubDomain(trigger1)}
                                className={errors1.subdomain ? "py-1 px-2 input" : "py-1 px-2 sub-domain"}
                                type="text"
                                {...register1("subdomain", {
                                    required: { value: true, message: "Sub domain required" },
                                    pattern: { value: /^[a-zA-Z0-9]{1,20}$/, message: "No spaces or special characters allowed" },
                                })}
                            />
                            <InputGroup.Text
                                style={{ background: "none", borderLeft: "none", color: "#6c757d", borderRadius: "4px" }}
                                className={errors1.subdomain ? "py-1 px-2 input" : "py-1 px-2 sub-domain-hint"}
                                id="basic-addon1"
                            >
                                .portal.skudrop.com
                            </InputGroup.Text>
                            <ReactTooltip
                                data-tooltip-id="basic-addon1"
                                place="bottom"
                                content="The Username allows you to personalise the SKUdrop link for your account."
                                className="regiter-subdomain"
                            />
                        </InputGroup>
                        {errors1.subdomain && <p className="mb-0 mt-1 error-message">{errors1.subdomain.message}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                        <Form.Label className="font-bold mb-0 mt-2">Address</Form.Label>
                        <Form.Control
                            className={errors1.adress ? "py-1 px-2 input" : "py-1 px-2 "}
                            type="email"
                            placeholder="Line 1"
                            {...register1("adress", { required: { value: true, message: "Adress required" } })}
                        />
                        {errors1.adress && <p className="mb-0 error-message">{errors1.adress.message}</p>}
                    </Form.Group>
                    <Form.Group className="d-flex mb-3 mt-1" controlId="formBasicEmail">
                        <div className="mr-1">
                            <Form.Control
                                className={errors1.city ? "py-1 px-2  input" : "py-1 px-2 "}
                                type="email"
                                placeholder="City"
                                {...register1("city", { required: { value: true, message: "City required" } })}
                            />
                            {errors1.city && <p className="mb-0 error-message">{errors1.city.message}</p>}
                        </div>
                        <div className="ml-1">
                            <Form.Control
                                className={errors1.state ? "py-1 px-2  input" : "py-1 px-2 "}
                                type="email"
                                placeholder="State"
                                {...register1("state", { required: { value: true, message: "State required" } })}
                            />
                            {errors1.state && <p className="mb-0 error-message">{errors1.state.message}</p>}
                        </div>
                    </Form.Group>
                    <Form.Group className="d-flex mb-3 mt-1" controlId="formBasicEmail">
                        <div className="w-50 mr-1">
                            <Form.Control
                                className={errors1.postalCode ? "py-1 px-2 input" : "py-1 px-2"}
                                type="email"
                                placeholder="Postal Code"
                                {...register1("postalCode", { required: { value: true, message: "Postal Code required" } })}
                            />
                            {errors1.postalCode && <p className="mb-0 error-message">{errors1.postalCode.message}</p>}
                        </div>
                        <div className="w-50 ml-1">
                            <Form.Select
                                className={errors.country ? "py-1 px-2 fz-14 input" : "py-1 px-2 fz-14"}
                                {...register("country", { required: { value: true, message: "Country required" } })}
                            >
                                <option value={undefined}>Country Code</option>
                                {COUNTRIES.map((item, i) => (
                                    <option value={item.code} key={i}>
                                        {item.code}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        NEXT
                    </Button>
                </Form>
            ) : (
                <Form className="auth-form shadow-sm large-form mt-4 max-w-430px" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Row className="d-flex justify-content-center">
                        <img src={logo} className="skudrop-logo mb-4" alt="" />
                    </Row>
                    {reqError && (
                        <Alert className="text-center" variant={"danger"} onClose={() => setReqError(false)} dismissible>
                            {Array.isArray(reqError) ? reqError[0] : reqError}
                        </Alert>
                    )}
                    <Form.Group className="mb-3" controlId="formBasicFirstName">
                        <Form.Label className="font-bold mb-0">Company Details</Form.Label>
                        <Form.Control
                            className={errors.companyName ? "py-1 px-2 input" : "py-1 px-2"}
                            type="text"
                            placeholder="Company Name"
                            {...register("companyName", { required: { value: true, message: "Company Name required" } })}
                        />
                        {errors.companyName && <p className="mb-0 error-message">{errors.companyName.message}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicLastName">
                        <Form.Label className="font-bold mb-0">Contact Name</Form.Label>
                        <Form.Control
                            className={errors.name ? "py-1 px-2 input" : "py-1 px-2"}
                            type="text"
                            placeholder="Name"
                            {...register("name", { required: { value: true, message: "Name required" } })}
                        />
                        {errors.name && <p className="mb-0 error-message">{errors.name.message}</p>}
                        <Form.Label className="font-bold mt-2 mb-0 mt-3">SKUdrop Username</Form.Label>
                        <InputGroup>
                            <Form.Control
                                style={{ borderRight: "none" }}
                                className={errors.subdomain ? "py-1 px-2 input" : "py-1 px-2 sub-domain"}
                                onKeyUp={() => onValidateSubDomain(trigger)}
                                type="text"
                                {...register("subdomain", {
                                    required: { value: true, message: "Sub domain required" },
                                    pattern: { value: /^[a-zA-Z0-9]{1,20}$/, message: "No spaces or special characters allowed" },
                                })}
                            />
                            <InputGroup.Text
                                style={{
                                    background: "none",
                                    borderLeft: "none",
                                    color: "#6c757d",
                                    borderRadius: "4px",
                                }}
                                className={errors.subdomain ? "py-1 px-2 input" : "py-1 px-2 sub-domain-hint"}
                                id="basic-addon1"
                            >
                                .skudrop.com
                            </InputGroup.Text>
                        </InputGroup>
                        <ReactTooltip
                            data-tooltip-id="basic-addon1"
                            place="bottom"
                            content="The Username allows you to personalise the SKUdrop link for your account."
                            className="regiter-subdomain"
                        />
                        {errors.subdomain && <p className="mb-0 mt-1 error-message">{errors.subdomain.message}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3 " controlId="formBasicEmail">
                        <Form.Label className="font-bold mb-0 mt-2">Billing Address</Form.Label>
                        <Form.Control
                            className={errors.adress ? "py-1 px-2 input" : "py-1 px-2 "}
                            placeholder="Line 1"
                            {...register("adress", { required: { value: true, message: "Adress Line 1 required" } })}
                        />
                        {errors.adress && <p className="mb-0 error-message">{errors.adress.message}</p>}
                    </Form.Group>
                    <Form.Group className="d-flex mb-3 mt-1" controlId="formBasicEmail">
                        <div className="mr-1">
                            <Form.Control
                                className={errors.city ? "py-1 px-2  input" : "py-1 px-2 "}
                                placeholder="City"
                                {...register("city", { required: { value: true, message: "City required" } })}
                            />
                            {errors.city && <p className="mb-0 error-message">{errors.city.message}</p>}
                        </div>
                        <div className="ml-1">
                            <Form.Control
                                className={errors.state ? "py-1 px-2  input" : "py-1 px-2 "}
                                placeholder="State"
                                {...register("state", { required: { value: true, message: "State required" } })}
                            />
                            {errors.state && <p className="mb-0 error-message">{errors.state.message}</p>}
                        </div>
                    </Form.Group>
                    <Form.Group className="d-flex mb-3 mt-1" controlId="formBasicEmail">
                        <div className="w-50 mr-1">
                            <Form.Control
                                className={errors.postalCode ? "py-1 px-2 input" : "py-1 px-2"}
                                placeholder="Postal Code"
                                {...register("postalCode", { required: { value: true, message: "Postal Code required" } })}
                            />
                            {errors.postalCode && <p className="mb-0 error-message">{errors.postalCode.message}</p>}
                        </div>
                        <div className="w-50 ml-1">
                            <Form.Select
                                className={errors.country ? "py-1 px-2 fz-14 input" : "py-1 px-2 fz-14"}
                                {...register("country", { required: { value: true, message: "Country required" } })}
                            >
                                <option value={undefined}>Country Code</option>
                                {COUNTRIES.map((item, i) => (
                                    <option value={item.code} key={i}>
                                        {item.code}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3 mt-1" controlId="formBasicEmail">
                        <Form.Label className="font-bold d-flex flex-row mb-1">
                            Phone
                            <p className="text-muted mb-0" style={{ marginLeft: 5 }}>
                                (optional)
                            </p>
                        </Form.Label>
                        <PhoneInput
                            specialLabel={""}
                            placeholder="Enter your phone"
                            inputStyle={{ padding: "5px", paddingLeft: "7px" }}
                            inputClass={isValidation ? "py-1 px-2 " : "py-1 px-2  input"}
                            {...register("phone")}
                            value={phone}
                            onChange={handleChangePhone}
                        />
                        {isValidation ? null : <p className="mb-0 error-message">invalid phone number</p>}
                    </Form.Group>
                    <Form.Group className="mb-3 mt-1" controlId="formBasicEmail">
                        <Form.Label className="font-bold d-flex flex-row mb-1">
                            Affiliate Code
                            {!affiliate_program_id && (
                                <p className="text-muted mb-0" style={{ marginLeft: 5 }}>
                                    (optional)
                                </p>
                            )}
                        </Form.Label>
                        {affiliate_program_id ? (
                            <div className="d-flex flex-column align-items-start justify-content-center">
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <p className="mb-0 mr-2 fz-15">Free subscription:</p>
                                    <div className="fz-15">{AFFILIATE_PERIODS_RELATION[affiliate_program_id.free_subscription_period]}</div>
                                </div>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <p className="mb-0 mr-2 fz-15">Prep fee discount:</p>
                                    <div className="fz-15">
                                        {affiliate_program_id.prep_fee_discount &&
                                            `${affiliate_program_id.prep_fee_discount}% for ${AFFILIATE_PERIODS_RELATION[affiliate_program_id.prep_fee_discount_period]}`}
                                    </div>
                                </div>
                                {affiliate_program_id.signup_bonus && (
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        <p className="mb-0 mr-2 fz-15">Shipping credit balance:</p>
                                        <div className="fz-15">${affiliate_program_id.signup_bonus}</div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <Form.Control className={errors.code ? "py-1 px-2 input" : "py-1 px-2 "} placeholder="Code" {...register("code")} />
                                <Button variant="primary" className="ml-2 h-34px" onClick={() => onAddCode()}>
                                    Add
                                </Button>
                            </div>
                        )}
                        {errors.code && <p className="mb-0 error-message">{errors.code.message}</p>}
                    </Form.Group>
                    <Row className="d-flex justify-content-center">
                        <Col className="border-bottom mb-3" style={{ maxWidth: "50%" }}></Col>
                    </Row>
                    <Form.Group className="mb-3">
                        <div className="d-flex justify-content-between">
                            <Form.Label className="font-bold d-flex flex-row mb-1">
                                <FaCreditCard style={{ marginRight: 5, width: 20, height: 20 }} />
                                Credit Card
                            </Form.Label>
                            <img src={powered_by_stripe} className="powered-by-stripe-register " alt="" />
                        </div>
                        <Col>
                            <CardNumberElement options={CardNumber} />
                        </Col>
                        <div className="d-flex justify-content-between">
                            <div className="card-mmyy">
                                <CardExpiryElement options={CardExpiry} />
                            </div>
                            <div className="card-cvc">
                                <CardCvcElement options={CardCvc} />
                            </div>
                        </div>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        NEXT
                    </Button>
                </Form>
            )}
        </Container>
    );
};

export default SignUpThirdStep;
