import Cookies from "universal-cookie";
import { TPatchWarehouse, TPostWarehouse } from "../redux/types/AdminWarehouseTypes/AdminWarehouse.types";
import { instence } from "./authAPI";

export const dashboardWarehouseAPI = {
    warehouseSetUp(token: string, data: TPostWarehouse) {
        return instence.post(`tenants/new-warehouse/set-up/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    patchWarehouse(cookies: Cookies, id: string, data: TPatchWarehouse) {
        return instence.patch(`tenants/warehouses-list/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
