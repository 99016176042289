import Cookies from "universal-cookie";
import { NotificationAxiosResponseType } from "../redux/types/AuthTypes/auth.types";
import { instence } from "./authAPI";

export const notifications = {
    getNotifications(cookies: Cookies, limit: number) {
        return instence.get<unknown, NotificationAxiosResponseType>(`notifications/?limit=${limit}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteNottification(cookies: Cookies, id: number) {
        return instence.delete(`notifications/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    readNottification(cookies: Cookies, id: number[]) {
        return instence.post(
            `notifications/`,
            {
                id_list: id,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    readAllNottification(cookies: Cookies) {
        return instence.post(
            `notifications/`,
            {
                select_all: true,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
