import Cookies from "universal-cookie";
import { ConfirmAddedToTenantAxiosResponse, UserListAxiosResponse } from "../redux/types/SettingsTypes/settings.types";
import { instence } from "./authAPI";

export const UserList = {
    getUserList(cookies: Cookies) {
        return instence.get<unknown, UserListAxiosResponse>(`/tenants/users-memberes/?limit=1000`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },

    AddUserToTenant(userEmail: string, cookies: Cookies) {
        return instence.post(
            `/users/invite/`,
            {
                email: userEmail,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },

    ConfirmAddedUserToTenant(uuid: string, userToken: string, password: string) {
        return instence.patch<unknown, ConfirmAddedToTenantAxiosResponse>(`/users/invite/activate/${uuid}/${userToken}/`, {
            password: password,
        });
    },

    DeleteUserFromTennant(email: string, cookies: Cookies) {
        return instence.delete(`/tenants/users-manipulator/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
            data: {
                email: email,
            },
        });
    },
    changeBillingAccess(cookies: Cookies, id: string, is_staff: boolean) {
        return instence.patch(
            `/users/update-staff/${id}/`,
            {
                is_staff,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
