import { useState } from "react";
import { toast, ToastOptions } from "react-toastify";

export default function useErrorHandler(): [string | null | string[] | boolean, (data: string | null | string[] | boolean) => void, (data: any) => void] {
    const [error, setError] = useState<string | null | string[] | boolean>(null);

    const handleError = (data: any) => {
        if (typeof data.message === "string") {
            setError(data.message);
        }
        if (typeof data.non_field_errors === "string") {
            setError(data.non_field_errors);
        }
        if (Array.isArray(data.non_field_errors)) {
            setError(data.non_field_errors[0]);
        }
        if (typeof data === "string") {
            setError(data);
        }
        if (Array.isArray(data)) {
            setError(data);
        }
        console.log(data);
    };

    return [error, setError, handleError];
}

export const displayToastMessage = ({
    data,
    tostOptions,
    extraMessage,
    component,
}: {
    data: any;
    tostOptions: { type: "info" | "success" | "warning" | "error"; options?: ToastOptions<{}> };
    extraMessage?: string;
    component?: { isAWS?: boolean };
}) => {
    let error: null | string = null;
    if (typeof data === "object" && Object.keys(data).length) {
        let tmp = data[Object.keys(data)[0]];
        error = Array.isArray(tmp) ? JSON.parse(JSON.stringify(tmp[0])) : JSON.parse(JSON.stringify(tmp));
    }
    if (typeof data?.message === "string") {
        error = data.message;
    }
    if (component?.isAWS && typeof data?.message === "string" && data.message.includes("You exceeded your quota")) {
        error = "Amazon API issues still persist. We recommend creating plans for 1 or 2 SKUs only or wait for a short period of time before trying again.";
    }
    if (typeof data?.non_field_errors === "string") {
        error = data.non_field_errors;
    }
    if (Array.isArray(data?.non_field_errors)) {
        error = data.non_field_errors.join(" \n");
    }
    if (typeof data === "string") {
        error = data;
    }
    if (Array.isArray(data) && typeof data[0] === "string") {
        error = data[0];
    }
    if (/<[a-z][\s\S]*>/i.test(data)) {
        let tempHTML = document.createElement("div");
        tempHTML.innerHTML = data;
        let exceptionValue = tempHTML.querySelector("pre.exception_value")?.textContent;
        let exceptionTitle = tempHTML.querySelector("title")?.textContent;
        error = exceptionValue || exceptionTitle ? `Server exception: "${exceptionValue || exceptionTitle}"` : null;
    }

    switch (tostOptions.type) {
        case "info": {
            toast.info(error || extraMessage, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                ...tostOptions.options,
            });
            break;
        }
        case "success": {
            toast.success(error || extraMessage, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                ...tostOptions.options,
            });
            break;
        }
        case "warning": {
            toast.warning(error || extraMessage, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                ...tostOptions.options,
            });
            break;
        }
        case "error": {
            toast.error(error || extraMessage, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                ...tostOptions.options,
            });
            break;
        }
    }
};

export const displayError = (data: any, extraMessage?: string, component?: { isAWS?: boolean }) => {
    let error: null | string = null;
    if (typeof data === "object" && Object.keys(data).length) {
        let tmp = data[Object.keys(data)[0]];
        error = Array.isArray(tmp) ? JSON.stringify(tmp[0]) : JSON.stringify(tmp);
    }
    if (typeof data?.message === "string") {
        error = data.message;
    }
    if (component?.isAWS && typeof data?.message === "string" && data.message.includes("You exceeded your quota")) {
        error = "Amazon API issues still persist. We recommend creating plans for 1 or 2 SKUs only or wait for a short period of time before trying again.";
    }
    if (typeof data?.non_field_errors === "string") {
        error = data.non_field_errors;
    }
    if (Array.isArray(data?.non_field_errors)) {
        error = data.non_field_errors.join(" \n");
    }
    if (typeof data === "string") {
        error = data;
    }
    if (Array.isArray(data) && typeof data[0] === "string") {
        error = data[0];
    }
    if (/<[a-z][\s\S]*>/i.test(data)) {
        let tempHTML = document.createElement("div");
        tempHTML.innerHTML = data;
        let exeptionValue = tempHTML.querySelector("pre.exception_value")?.textContent;
        let exeptionTitle = tempHTML.querySelector("title")?.textContent;
        error = exeptionValue || exeptionTitle ? `Server exeption: "${exeptionValue || exeptionTitle}"` : null;
    }

    toast.error(error || extraMessage, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export const displaySuccess = (data: any) => {
    let error: null | string = null;
    if (typeof data?.message === "string") {
        error = data.message;
    }
    if (typeof data?.non_field_errors === "string" || Array.isArray(data?.non_field_errors)) {
        error = data.non_field_errors;
    }
    if (typeof data === "string") {
        error = data;
    }
    if (Array.isArray(data)) {
        error = data[0];
    }
    toast.success(error, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export const displayInfo = (data: string, extra?: { toastId?: string }) => {
    toast.info(data, {
        autoClose: false,
        position: "top-right",
        closeOnClick: true,
        draggable: false,
        theme: "colored",
        toastId: extra?.toastId,
    });
};
