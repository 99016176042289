import {
    TGetAffiliateDetails,
    TUpdateAffiliateReqData,
    PostAffiliateReqType,
    GetAffiliateAxiosRes,
    TPostAffiliateDetailsFileReqData,
} from "./../redux/types/AffiliateTypes/Affiliate.types";
import { TPatchTenantShippingCreditsData } from "./../redux/types/ChargingRatesTypes/ChargingRates.types";

import { instence } from "./authAPI";
import Cookies from "universal-cookie";

export const affiliateAPI = {
    getAffiliate(token: string, items: number) {
        return instence.get<unknown, GetAffiliateAxiosRes>(`/affiliate/?limit=${items}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getAffiliateAll(token: string, items: number, offset: number, search: string, sortBy?: string) {
        return instence.get<unknown, GetAffiliateAxiosRes>(
            `/affiliate/?limit=${items}&offset=${offset}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    postAffiliate(token: string, data: PostAffiliateReqType) {
        return instence.post(`/affiliate/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    onPayAffiliate(token: string, id: string) {
        return instence.post(
            `/affiliate/manual/payout/`,
            { id },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    deleteAffiliate(token: string, id: string) {
        return instence.delete(`/affiliate/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    updateAffiliate(token: string, id: string, data: TUpdateAffiliateReqData) {
        return instence.patch(`/affiliate/code/update_code/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getAffiliateDetailsData(cookies: Cookies, id: string) {
        return instence.get<unknown, TGetAffiliateDetails>(`/affiliate/details/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postAffiliateDetailsFile(cookies: Cookies, data: TPostAffiliateDetailsFileReqData) {
        return instence.post<string>("/affiliate/generate_file/", data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    updateAffiliateInvestment(cookies: Cookies, id: string, total_investment: number) {
        return instence.patch(
            `/affiliate/details/${id}/`,
            {
                total_investments: total_investment,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    patchTenantShippingCredits(cookies: Cookies, tenantId: string, data: TPatchTenantShippingCreditsData) {
        return instence.patch(`/affiliate/add-credit/${tenantId}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
